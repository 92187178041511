import React, { useCallback } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import classes from './Footer.module.scss';
import logoLight from '@assets/images/logo-light.svg';
import googleTranslate from '@assets/images/powered-google-translate.svg';
import ReCaptchaInfo from '@components/atoms/ReCaptchaInfo/ReCaptchaInfo';
import NewsLetterForm2 from '@components/molecules/NewsletterForm2/NewsLetterForm2';
import { sendToastNotification } from '../../../../../utils/main.utils';
import { ReactComponent as TwitterIcon } from '../../../../../assets/images/twitter.svg';
import { ReactComponent as TelegramIcon } from '../../../../../assets/images/telegram.svg';

function Footer() {
  const navigate = useNavigate();

  const onSubscribeError = useCallback(() => {
    sendToastNotification('Please provide a valid email address', 'error');
  }, []);

  return (
    <div className={classes.footer} data-testid="footer">
      <div className={classes.footerMain}>
        <div className={classes.info}>
          <figure className={classes.footerLogo} aria-hidden onClick={() => navigate('/')}>
            <img src={logoLight} alt="logo" width={'100%'} height={'100%'} />
          </figure>
          <div className={classes.information}>
            <p className={classes.informationText}>
              At Airdrop.com, we offer the latest reward distributions, contests, raffles, and
              giveaways. With many years of experience in the field, Airdrop.com has consistently
              delivered top-notch services.
            </p>
            <p className={classes.informationText}>
              Our policy is to prioritize quality over quantity. All of our airdrops are carefully
              selected and manually tested to ensure their worth.
            </p>
          </div>
          <div className={classes.socials}>
            <NavLink to={'/telegram-channel'} className={classes.footerLink}>
              <TelegramIcon width={24} height={24} fill={'#2AABEE'} />
            </NavLink>
            <NavLink to={'/twitter-page'} className={classes.footerLink}>
              <span className={classes.headerLinkIcon}>
                <TwitterIcon width={24} height={24} fill={'#1D9BF0'} />
              </span>
            </NavLink>
          </div>
        </div>
        <div className={classes.links}>
          <div className={classes.linksInner}>
            <NavLink to={'/'} className={classes.link}>
              Home
            </NavLink>
            <NavLink to={'/rewards'} className={classes.link}>
              Rewards
            </NavLink>
            <NavLink to={'/contact-us'} className={classes.link}>
              Contact
            </NavLink>
            <NavLink to={'/airdrop-bounty'} className={classes.link}>
              Bounty
            </NavLink>
            <NavLink to={'/faq'} className={classes.link}>
              FAQ
            </NavLink>
            <NavLink to={'/newsletter'} className={classes.link}>
              Newsletter
            </NavLink>
            <NavLink to={'/about-us'} className={classes.link}>
              About
            </NavLink>
          </div>
        </div>
        <div className={classes.newsletter}>
          <p className={classes.newsletterText}>
            Join our newsletter to stay up to date on features and releases.
          </p>
          <NewsLetterForm2 inputVariant={'gray'} onError={onSubscribeError} />
          <ReCaptchaInfo containerClassname={classes.reCaptchaInfoContainer} />
          <p className={classes.newsletterInfo}>
            By subscribing you agree to with our privacy policy and provide consent to receive
            updates from us.
          </p>
        </div>
      </div>

      <div className={classes.footerBottom}>
        <div className={classes.footerBottomInner}>
          <div>© 2024 Airdrop.com • All rights reserved</div>
          <div className={classes.privacy}>
            <NavLink to={'/terms-and-conditions'} className={classes.privacyLink}>
              Terms & Conditions
            </NavLink>
            <NavLink to={'/privacy-policy'} className={classes.privacyLink}>
              Privacy Policy
            </NavLink>
          </div>
          <figure className={classes.googleTranslate}>
            <img src={googleTranslate} alt="Google Translate" />
          </figure>
        </div>
      </div>
    </div>
  );
}

export default Footer;
